const mainSwiperElement = document.querySelector('.MainSwiper');
const thumbsSwiperElement = document.querySelector('.ThumbsSwiper');

const mainSwiperSlideTemplate = document.querySelector('#main-swiper-slide');
const thumbsSwiperSlideTemplate = document.querySelector('#thumbs-swiper-slide');

const TOTAL_IMAGE_COUNT = 599;

// Drawer component.

class Drawer extends HTMLElement {
  constructor() {
    super();

    this.drawerId = this.getAttribute('data-drawer-id');

    this.elements = {
      content: this.querySelector('.Drawer__Content'),
    };

    this.addEventListeners();
  }

  addEventListeners() {
    this.addEventListener('click', (event) => {
      if (event.target !== this.elements.content) {
        this.closeDrawer();
      }
    });

    window.addEventListener(`open-drawer:${this.drawerId}`, this.openDrawer.bind(this));
    window.addEventListener(`close-drawer:${this.drawerId}`, this.closeDrawer.bind(this));
  }

  openDrawer() {
    this.setAttribute('aria-hidden', 'false');

    window.requestAnimationFrame(() => {
      this.classList.add('Drawer--Visible');
    });
  }

  closeDrawer() {
    this.classList.remove('Drawer--Visible');

    setTimeout(() => {
      this.setAttribute('aria-hidden', 'true');
    }, 300);
  }
}

customElements.define('c-drawer', Drawer);

// Password overlay component.

class PasswordOverlay extends HTMLElement {
  constructor() {
    super();

    this.elements = {
      input: this.querySelector('input'),
      defaultState: this.querySelector('.PasswordOverlay__DefaultState'),
      passwordState: this.querySelector('.PasswordOverlay__PasswordState'),
      successState: this.querySelector('.PasswordOverlay__SuccessState'),
    };

    this.password = '2006';

    const localStoragePassword = window.localStorage.getItem('verringelung-password');

    if (localStoragePassword === this.password) {
      this.hide();
    } else {
      this.hideDefaultState();
      this.showPasswordState();

      this.elements.input.focus();
    }

    this.handleInput = this.handleInput.bind(this);

    this.addEventListeners();
  }

  addEventListeners() {
    this.elements.input.addEventListener('input', this.handleInput);
  }

  handleInput() {
    if (this.elements.input.value === this.password) {
      window.localStorage.setItem('verringelung-password', this.password);

      this.successRoutine();
    }
  }

  successRoutine() {
    this.elements.input.value = '';
    this.elements.input.setAttribute('disabled', 'true');

    this.hideDefaultState();
    this.hidePasswordState();
    this.showSuccessState();
    this.animateRings();

    window.setTimeout(() => {
      this.hide();
    }, 1500);
  }

  animateRings() {
    window.requestAnimationFrame(() => {
      this.elements.successState.classList.remove('PasswordOverlay__SuccessState--Loading');
    });
  }

  showSuccessState() {
    this.elements.successState.classList.add('PasswordOverlay__SuccessState--Active');
    this.elements.successState.classList.add('PasswordOverlay__SuccessState--Loading');
  }

  hideDefaultState() {
    this.elements.defaultState.classList.remove('PasswordOverlay__DefaultState--Active');
  }

  showPasswordState() {
    this.elements.passwordState.classList.add('PasswordOverlay__PasswordState--Active');
  }

  hidePasswordState() {
    this.elements.passwordState.classList.remove('PasswordOverlay__PasswordState--Active');
  }

  hide() {
    this.classList.remove('PasswordOverlay--Active');
  }
}

customElements.define('password-overlay', PasswordOverlay);

// Action listeners.

const actionElements = document.querySelectorAll('[data-action]');

actionElements.forEach((actionElement) => {
  // TODO: Add support for more action types.
  const ACTION_TYPE = 'click';

  actionElement.addEventListener(ACTION_TYPE, () => {
    const action = actionElement.getAttribute('data-action');
    const event = new CustomEvent(action);

    window.dispatchEvent(event);
  });
});

// Create all swiper slides and append them to their wrappers.

for (let i = 0; i < TOTAL_IMAGE_COUNT; i++) {
  // Main Swiper.

  const mainSwiperSlide = mainSwiperSlideTemplate.content
    .cloneNode(true)
    .querySelector('.swiper-slide');

  mainSwiperSlide.setAttribute('data-index', i);

  mainSwiperSlide
    .querySelector('img')
    .setAttribute('src', `./photos/${i.toString().padStart(4, '0')}.jpg`);

  mainSwiperElement.querySelector('.swiper-wrapper').appendChild(mainSwiperSlide);

  // Thumbs Swiper.

  const thumbsSwiperSlide = thumbsSwiperSlideTemplate.content
    .cloneNode(true)
    .querySelector('.swiper-slide');

  thumbsSwiperSlide
    .querySelector('img')
    .setAttribute('src', `./photos/${i.toString().padStart(4, '0')}.jpg`);

  thumbsSwiperElement.querySelector('.swiper-wrapper').appendChild(thumbsSwiperSlide);
}

const thumbsSwiper = new Swiper(thumbsSwiperElement, {
  spaceBetween: 8,
  slidesPerView: 6,
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const swiper = new Swiper(mainSwiperElement, {
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },

  lazyPreloadPrevNext: 1,
  autoHeight: true,
  zoom: true,
  thumbs: {
    swiper: thumbsSwiper,
  },

  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

// Header scroll listener.

const headerElement = document.querySelector('header');

function handleDocumentScroll() {
  const headerHeight = headerElement.offsetHeight;

  const isScrolled = window.scrollY > headerHeight;

  headerElement.classList.toggle('Header--Scrolled', isScrolled);
}

document.addEventListener('scroll', handleDocumentScroll);

// Additional gallery logic.

const galleryImages = document.querySelectorAll('.MainSwiper .swiper-slide');

// Get viewed images from local storage and add `new` tag.

const localStorage = window.localStorage;

const viewedImages = JSON.parse(localStorage.getItem('viewed-images')) || [];

galleryImages.forEach((galleryImage) => {
  const index = parseInt(galleryImage.getAttribute('data-index'));

  if (!viewedImages.includes(index)) {
    galleryImage.setAttribute('data-new', 'true');
  }
});

// Intersection observer for gallery images.

const galleryImagesObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const index = parseInt(entry.target.getAttribute('data-index'));

        if (!viewedImages.includes(index)) {
          viewedImages.push(index);

          localStorage.setItem('viewed-images', JSON.stringify(viewedImages));
        }
      }
    });
  },
  {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  }
);

galleryImages.forEach((galleryImage) => {
  galleryImagesObserver.observe(galleryImage);
});
